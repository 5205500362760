@import "./variables";
@import "../_all/root";

/** This file is the main output for ELFI client, to be 'required' by entrypoint index.js */
/** This file includes all styles defined in './_all/_root.scss' */
/** Use the area below to override any custom properties, global styles, or create definitions specific to this client */

:root {
	--palette-primary-main: #{$elfi-palette-primary-main};
	--palette-secondary-main: #{$elfi-palette-secondary-main};
	--palette-error-main: #{$elfi-palette-error-main};
	--palette-header-main: #FFF;
	--palette-info-main: #{$elfi-palette-info-main};
	--palette-neutral-light: #{$elfi-palette-neutral-light};
	--palette-neutral-main: #{$elfi-palette-neutral-main};
	--palette-neutral-dark: #{$elfi-palette-neutral-dark};
	--palette-link-main: #{$elfi-palette-link-main};
	--palette-text-primary: #{$elfi-palette-text-primary};
	--palette-text-secondary: #{$elfi-palette-text-secondary};
}