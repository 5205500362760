.cmp-footer {

	a {
		text-decoration: underline;

		&:focus,
		&:hover {
			text-decoration: none;
		}

		&:focus {
			@include faux-focus;
		}
	}
}