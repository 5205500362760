@use "sass:math";

.cmp-task-header {
	--task-close-height: #{get-rem(2px)};
	--task-close-width: #{get-rem(18px)};
	--task-close-hypot: #{math.hypot(18, 18)};
	--task-header-height: #{get-rem(64px)};
	align-items: center;
	border-bottom: 1px solid var(--palette-neutral-main);
	display: grid;
	grid-template-columns: 4fr 1fr;
	margin-bottom: get-rem(14px);
	min-height: var(--task-header-height);
	padding: 0 get-rem(14px);
	position: relative;
}

.cmp-task-header--isBack {
	grid-template-columns: 1fr 1fr;
	margin-bottom: var(--task-header-height);

	.cmp-task-header__label {
		position: absolute;
		text-align: center;
		translate: 0 get-rem(64px);
		width: 100%;
	}
}

.cmp-task-header__back-label,
.cmp-task-header__close-label {
	@include a11y-hidden;
}

.cmp-task-complete {
	background: url($completeCheckmarkBase64) no-repeat top center / get-rem(175px);
	padding-top: get-rem(200px);
	margin: get-rem(14px) auto get-rem(64px);
	text-align: center;
}