#defaultFormNarrow {
	margin: auto;
	max-width: get-rem(325px);

	.cmp-button {
		width: 100%;
	}

	.cmp-form-text__password-reveal:focus {
		@include faux-focus;
	}
}

#rememberDeviceForm {
	margin: auto;
	max-width: get-rem(540px);

	.MuiFormControlLabel-asterisk {
		@include a11y-hidden;
	}
}

#defaultForm,
#rememberDeviceForm {

	.cmp-button {
		display: flex;
		width: 100%;
		margin: auto;
		max-width: get-rem(325px);
	}
}