.a11y-skip-links {
	position: absolute;
	inset: get-rem(24px) auto 0 get-rem(24px);
	text-align: center;
}

.a11y-skip-links__item {
	@include a11y-hidden;
	background-color: white;
	border-radius: get-rem(2px);
	box-shadow: 0 0 get-rem(8px) rgb(255, 0, 255);
	font-size: get-rem(12px);
	padding: get-rem(8px);

	&:focus {
		@include a11y-unhidden;
	}
}