@mixin a11y-hidden() {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

@mixin a11y-unhidden() {
	clip: unset;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}

@mixin faux-focus() {
	outline: 0.1rem solid Highlight;

	@media (-webkit-min-device-pixel-ratio: 0) {
		outline-color: -webkit-focus-ring-color;
		outline-style: auto;
	}
}