.cmp-remember-device-list {
	border-top: get-rem(1px) solid var(--palette-neutral-main);

	label {
		align-items: start;
	}

	.cmp-remember-device__details {
		background-color: var(--palette-neutral-light);
		display: none;
		margin: get-rem(8px) 0 0;
		padding: get-rem(14px);
	}

	.MuiFormControlLabel-label {
		padding-top: get-rem(10px);
	}

	.Mui-checked+.MuiFormControlLabel-label>.cmp-remember-device__details {
		display: block;
	}

	.Mui-checked+.MuiFormControlLabel-label>.cmp-remember-device__value {
		font-weight: 700;
	}
}