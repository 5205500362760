.cmp-paperless-programs-list {

	li {
		margin-top: get-rem(4px);

		&:first-of-type {
			margin-top: 0;
		}
	}

	li>span {
		@include a11y-hidden;
	}

	li[data-enrolled="true"] {
		list-style: none;
		position: relative;

		&::before {
			background: url($checkmarkGreenBase64) no-repeat center center;
			display: block;
			content: "";
			height: get-rem(14px);
			position: absolute;
			top: get-rem(3px);
			left: get-rem(-22px);
			width: get-rem(18px);
		}
	}
}