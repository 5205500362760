.cmp-header {
	--header-height: #{get-rem(65px)};
	--header-logo-padding: #{get-rem(5px)};
}

.cmp-header__inner {
	min-height: var(--header-height);
}

.cmp-header__logo {
	align-items: center;
	display: flex;
	justify-content: center;
	padding: var(--header-logo-padding);

	&[data-bp="lg"] {
		justify-content: flex-start;
	}

	img {
		display: block;
		max-height: calc(var(--header-height) - (2 * var(--header-logo-padding)));
		min-height: 6rem;
	}
}