.cmp-terms-and-conditions__content {
	border: get-rem(1px) solid var(--palette-neutral-main);
	margin: 0 auto get-rem(20px);
	max-height: get-rem(280px);
	max-width: get-rem(540px);
	overflow-y: auto;
	padding: get-rem(14px);
	@include scrollbar;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		font-family: var(--typography-primary-font-light);
		font-weight: 300;
		margin-bottom: get-rem(16px);
	}

	h1,
	h2,
	h3 {
		font-size: get-rem(24px);
		line-height: 1.167;
	}

	h4 {
		font-size: get-rem(18px);
		font-family: 'Open Sans Light', 'Open Sans', sans-serif;
		font-weight: 700;
		line-height: 1.235;
	}

	h5 {
		font-size: get-rem(14px);
		font-family: 'Open Sans Light', 'Open Sans', sans-serif;
		font-weight: 700;
		line-height: 1.334;
	}

	h6 {
		font-size: get-rem(14px);
		line-height: 1.6;
	}

	p,
	ul {
		margin-bottom: get-rem(14px);
	}

	ul {
		margin-left: get-rem(20px);
	}

	li {
		list-style: disc;
		margin-bottom: get-rem(8px);
	}
}