@import "../_all/variables";
@import "../../functions/get-rem";

:root {
	--palette-primary-main: #{$aes-palette-primary-main};
	--palette-secondary-main: #{$aes-palette-secondary-main};
	--palette-error-main: #{$aes-palette-error-main};
	--palette-info-main: #{$aes-palette-info-main};
	--palette-neutral-light: #{$aes-palette-neutral-light};
	--palette-neutral-main: #{$aes-palette-neutral-main};
	--palette-neutral-dark: #{$aes-palette-neutral-dark};
	--palette-link-main: #{$aes-palette-link-main};
	--palette-text-primary: #{$aes-palette-text-primary};
	--palette-text-secondary: #{$aes-palette-text-secondary};
	--typography-primary-font: #{$primaryFont};
	--typography-primary-font-light: #{"Open Sans Light", $primaryFont};
	--typography-primary-font-semi-bold: #{"Open Sans SemiBold", $primaryFont};
	--typography-primary-font-bold: #{"Open Sans Bold", $primaryFont};
	--typography-primary-font-extra-bold: #{"Open Sans ExtraBold", $primaryFontBold};

	--footer-background-color: #3B4557;
}

@import "../_all/reset";

@import "../../mixins/a11y";
@import "../../mixins/scrollbar";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html {
	font-size: 62.5%;
	min-height: 100vh;
}

body {
	color: var(--palette-text-primary);
	min-width: 375px;
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

:focus {
	outline: 0;
}

main[data-template-id="template-task"] {
	border-top: 8px solid var(--palette-primary-main);
	min-height: 100vh;
	padding: get-rem(14px);
}

.no-wrap {
	white-space: nowrap;
}

.a11y-hidden {
	@include a11y-hidden;
}

@import "../../components/button";
@import "../../components/header";
@import "../../components/form";
@import "../../components/form-text";
@import "../../components/footer";
@import "../../components/lists";
@import "../../components/paperless-preferences";
@import "../../components/remember-device";
@import "../../components/select-device";
@import "../../components/skip-links";
@import "../../components/task";
@import "../../components/terms-and-conditions";