.cmp-select-device {
	border-bottom: get-rem(1px) solid var(--palette-neutral-main);
	border-top: get-rem(1px) solid var(--palette-neutral-main);
	margin-bottom: get-rem(28px);
}

.cmp-select-device__item {
	align-items: center;
	display: grid;
	grid-template-columns: get-rem(44px) auto get-rem(130px);
	min-height: get-rem(70px);

	&:nth-child(n + 2) {
		border-top: get-rem(1px) solid var(--palette-neutral-main);
	}
}

.cmp-select-device__error {
	grid-template-columns: 1fr;
}

.cmp-select-device__item-desc {
	font-family: var(--typography-primary-font-light);
	font-size: get-rem(14px);
	font-weight: 300;
}

.cmp-select-device__item-icon {
	height: get-rem(32px);
	margin: 0 get-rem(6px);
	width: get-rem(32px);


	[data-type="email"]>& {
		background: url(#{$mailIconBase64}) no-repeat center center;
	}

	[data-type="sms"]>& {
		background: url(#{$chatIconBase64}) no-repeat center center;
	}

	[data-type="voice"]>& {
		background: url(#{$callIconBase64}) no-repeat center center;
	}
}